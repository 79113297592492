import request from '@/api/service'

const api = {
  // 获取短信余额
  getSmsBalance: '/hotel/hotelSmsRecharge/getSmsBalance',
  // 查询任务列表(系统任务/自定义任务)
  getShortMessage: '/event/shortMessage/getShortMessageList',
  // 创建任务
  addShortMessage: '/event/shortMessage/add',
  // 编辑任务
  editShortMessage: '/event/shortMessage/edit',
  // 任务明细
  taskDetail: '/event/shortMessageLog/getPage',
  // 删除任务
  deleteTask: '/event/shortMessage/delete/',
}

export function getSmsBalance(params) {
  return request({
    url: api.getSmsBalance,
    method: 'get',
    params,
  })
}

export function getShortMessage(data) {
  return request({
    url: api.getShortMessage,
    method: 'POST',
    data,
  })
}

export function addShortMessage(data) {
  return request({
    url: api.addShortMessage,
    method: 'POST',
    data,
  })
}

export function editShortMessage(data) {
  return request({
    url: api.editShortMessage,
    method: 'POST',
    data,
  })
}

export function taskDetail(params, data) {
  return request({
    url: api.taskDetail,
    method: 'POST',
    params,
    data,
  })
}

export function deleteTask(id) {
  return request({
    url: api.deleteTask + id,
    method: 'GET',
  })
}
