<!-- 任务列表 -->
<template>
  <section class="cont timingMessages">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <el-tabs v-model="activeName">
        <el-tab-pane label="全部" name="all">
          <span class="font-14 m-right-10">剩余短信: {{ smsBalance }} 条</span>
          <el-link type="primary" @click="$router.push({ path: '/balance_management' })">去充值</el-link>
          <div class="m-top-10">
            <span>系统任务</span>
            <div class="task" v-if="systemList.length">
              <el-card class="item" v-for="(item, index) in systemList" :key="item.id" shadow="hover">
                <div slot="header">
                  <span>{{ item.name }}</span>
                  <el-switch class="float-r" @change="editState(item, index)" v-model="systemState[index]" active-value="YES" inactive-value="NO"></el-switch>
                  <span class="float-r p-right-10" :class="item.moneyState ? 'c-red' : 'c-primary'" v-if="systemState[index] === 'YES'">
                    {{ item.moneyState ? '余额不足' : '正常使用' }}
                  </span>
                </div>
                <div class="text text-ellipis-3">短信内容：{{ item.messageText }}</div>
                <div class="footer">
                  <span class="c-grey font-14">无法编辑</span>
                  <el-link class="float-r" type="primary" @click="detail(item, true)">明细</el-link>
                </div>
              </el-card>
            </div>
            <el-empty :image="require('@/assets/group_empty.png')" v-else></el-empty>
          </div>
          <div class="m-top-50">
            <span>自定义任务</span>
            <div class="task" v-if="customList.length">
              <el-card class="item" v-for="(item, index) in customList" :key="item.id" shadow="hover">
                <div slot="header">
                  <span>{{ item.name }}</span>
                  <el-switch class="float-r" @change="editState(item, index)" v-model="customState[index]" active-value="YES" inactive-value="NO"></el-switch>
                  <span class="float-r p-right-10" :class="item.moneyState ? 'c-red' : 'c-primary'" v-if="customState[index] === 'YES'">
                    {{ item.moneyState ? '余额不足' : '正常使用' }}
                  </span>
                </div>
                <div class="text text-ellipis-3">短信内容：{{ item.messageText }}</div>
                <div class="footer">
                  <el-link type="primary" @click="edit(item)">编辑</el-link>
                  <el-link class="float-r" type="primary" @click="detail(item, true)">明细</el-link>
                </div>
              </el-card>
            </div>
            <el-empty :image="require('@/assets/group_empty.png')" v-else></el-empty>
          </div>
        </el-tab-pane>
        <el-tab-pane label="系统任务" name="system">
          <span class="font-14 m-right-10">剩余短信: {{ smsBalance }} 条</span>
          <el-link type="primary" @click="$router.push({ path: '/balance_management' })">去充值</el-link>
          <div class="task" v-if="systemList.length">
            <el-card class="item" v-for="(item, index) in systemList" :key="item.id" shadow="hover">
              <div slot="header">
                <span>{{ item.name }}</span>
                <el-switch class="float-r" @change="editState(item, index)" v-model="systemState[index]" active-value="YES" inactive-value="NO"></el-switch>
                <span class="float-r p-right-10" :class="item.moneyState ? 'c-red' : 'c-primary'" v-if="systemState[index] === 'YES'">
                  {{ item.moneyState ? '余额不足' : '正常使用' }}
                </span>
              </div>
              <div class="text text-ellipis-3">短信内容：{{ item.messageText }}</div>
              <div class="footer">
                <span class="c-grey font-14">无法编辑</span>
                <el-link class="float-r" type="primary" @click="detail(item, true)">明细</el-link>
              </div>
            </el-card>
          </div>
          <el-empty :image="require('@/assets/group_empty.png')" v-else></el-empty>
        </el-tab-pane>
        <el-tab-pane label="自定义任务" name="custom">
          <span class="font-14 m-right-10">剩余短信: {{ smsBalance }} 条</span>
          <el-link type="primary" @click="$router.push({ path: '/balance_management' })">去充值</el-link>
          <div class="task" v-if="customList.length">
            <el-card class="item" v-for="(item, index) in customList" :key="item.id" shadow="hover">
              <div slot="header">
                <span>{{ item.name }}</span>
                <el-switch class="float-r" @change="editState(item, index)" v-model="customState[index]" active-value="YES" inactive-value="NO"></el-switch>
                <span class="float-r p-right-10" :class="item.moneyState ? 'c-red' : 'c-primary'" v-if="customState[index] === 'YES'">
                  {{ item.moneyState ? '余额不足' : '正常使用' }}
                </span>
              </div>
              <div class="text text-ellipis-3">短信内容：{{ item.messageText }}</div>
              <div class="footer">
                <el-link type="primary" @click="edit(item)">编辑</el-link>
                <el-link class="float-r" type="primary" @click="detail(item, true)">明细</el-link>
              </div>
            </el-card>
          </div>
          <el-empty :image="require('@/assets/group_empty.png')" v-else></el-empty>
        </el-tab-pane>
      </el-tabs>
      <el-button type="primary" plain class="btn" @click="create" v-show="activeName !== 'system'">创建任务</el-button>
    </el-row>

    <!-- 任务明细 -->
    <el-dialog :visible.sync="detailShow" width="600px">
      <div slot="title">
        <span class="text-color font-18">任务明细</span>
        <span class="text-color font-14 m-left-5">{{ form.name }}</span>
      </div>
      <el-table :data="gridData">
        <el-table-column type="index" label="序号" width="70"></el-table-column>
        <el-table-column label="发送时间">
          <template slot-scope="scope">
            {{ scope.row.sendTime | time }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="用户手机号"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination v-show="total" :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" />
    </el-dialog>

    <!-- 创建/编辑 任务 -->
    <el-dialog :title="pageType === 'create' ? '创建任务' : '编辑任务'" :visible.sync="taskShow" width="600px">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="任务名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称" maxlength="10" show-word-limit class="width-220"></el-input>
        </el-form-item>
        <el-form-item label="选择条件" prop="condition">
          <el-input v-model="form.condition" class="width-220" disabled></el-input>
          <el-button type="primary" size="mini" class="m-left-15" @click="select">选择</el-button>
        </el-form-item>
        <el-form-item label="发送时间" prop="sendType">
          <el-radio-group v-model="form.sendType">
            <el-radio :label="0">立即发送</el-radio>
            <el-radio :label="1">延迟发送</el-radio>
          </el-radio-group>
          <span v-show="form.sendType === 1">
            <el-input-number v-model="form.time" size="mini" class="width-120 m-left-15" :min="1" :max="3600"></el-input-number>
            &nbsp;&nbsp;秒
          </span>
        </el-form-item>
        <el-form-item label="短信内容" prop="content">
          <el-input type="textarea" v-model="form.content" placeholder="【短信签名】 请输入内容" maxlength="60" :rows="4" resize="none" show-word-limit class="width-370"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="handleDel" v-if="pageType === 'edit'">删 除</el-button>
        <el-button @click="taskShow = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 条件管理 -->
    <el-dialog title="条件管理" :visible.sync="conditionShow" :close-on-click-modal="false" width="800px">
      <condition ref="condition" @condition="condition" @close="conditionShow = false" :all="true" />
    </el-dialog>
  </section>
</template>

<script>
  import { getSmsBalance, getShortMessage, addShortMessage, editShortMessage, taskDetail, deleteTask } from '@/api/smtech/marketingTask'
  import condition from '@/components/local/conditionMan'
  import { mapState } from 'vuex'
  export default {
    name: 'timingMessages',
    components: { condition },
    data() {
      let validCondition = (rule, value, callback) => {
        if (this.form.condition === '未选择') {
          callback(new Error('请选择条件'))
        } else {
          callback()
        }
      }
      let validTimeState = (rule, value, callback) => {
        if (this.form.sendType == 1 && !this.form.time) {
          callback(new Error('请选择发送时间'))
        } else {
          callback()
        }
      }
      return {
        crumbs: new Map([['营销云'], ['营销任务'], ['任务列表']]),
        activeName: 'all',    // 标签页名称
        smsBalance: 0,        // 短信余额
        detailShow: false,    // 任务明细弹窗状态
        taskShow: false,      // 任务弹窗状态
        conditionShow: false, // 条件弹窗状态
        pageType: 'create',   // 任务弹窗类型(创建/编辑)
        count: 0,             // 记录创建任务时打开条件弹窗次数
        form: {
          id: '',             // 编辑时的ID
          name: '',           // 任务名称
          condition: '未选择', // 选择条件状态文字
          sendType: 0,        // 发送类型
          time: 1,            // 延迟时间(秒)
          propStr: [],        // 用户属性
          touchStr: [],       // 触发条件
          content: '',        // 短信内容
        },
        rules: {
          name: [{ required: true, message: '请输入任务名称', trigger: 'blur' }],
          condition: [{ required: true, validator: validCondition, trigger: 'change' }],
          sendType: [{ required: true, validator: validTimeState, trigger: 'change' }],
          content: [{ required: true, message: '请输入短信内容', trigger: 'blur' }],
        },
        gridData: [],          // 任务明细列表
        systemList: [],        // 系统任务列表
        systemState: [],       // 系统状态数组
        customList: [],        // 自定义任务列表
        customState: [],       // 自定义状态数组

        total: 1,              // 用户列表总条目数
        page: 1,               // 当前页 默认第一页
        limit: 10,             // 每页显示数

        prompt: '提示！',
        confirm_remove: '是否删除自定义任务？将无法使用，并且在列表删除',
        remove_success: '删除成功！',
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    mounted() {
      this.getSmsBalance()
      this.getTaskList()
    },
    methods: {
      // 获取酒店短信余额
      getSmsBalance() {
        getSmsBalance({ hotelId: this.hotelInfo.id }).then(({ success, data }) => {
          if (success) {
            this.smsBalance = data.smsBalance
          }
        })
      },
      // 任务列表
      getTaskList() {
        let data = { hotelId: this.hotelInfo.id }
        // 系统任务
        getShortMessage(data).then(({ success, records }) => {
          if (success) {
            let systemList = [], customList = [], systemState = [], customState = []
            for (let i = 0; i < records.length; i++) {
              if (records[i].type) {
                customState.push(records[i].state)
                customList.push(records[i])
              } else if (records[i].type === 0 && records[i].systemState === 'YES') {
                systemState.push(records[i].state)
                systemList.push(records[i])
              }
            }
            this.systemList = systemList
            this.systemState = systemState
            this.customList = customList
            this.customState = customState
          }
        })
      },
      // 任务明细
      detail(item, bool) {
        if (bool) {
          this.page = 1
          this.limit = 10
        }
        if (item) {
          this.form.name = item.name
          this.form.id = item.id
        }
        let params = { page: this.page, limit: this.limit },
          data = { hotelId: this.hotelInfo.id, messageId: this.form.id }
        taskDetail(params, data).then(({ success, records, total }) => {
          if (success) {
            this.detailShow = true
            this.gridData = records
            this.total = total
          }
        })
      },
      // 选择(触发条件弹窗)
      select() {
        this.conditionShow = true
        this.$nextTick(() => {
          if (this.pageType === 'create' && !this.count) {
            this.$refs.condition.transmit(undefined, undefined)
          } else if (this.pageType === 'edit') {
            this.$refs.condition.transmit(this.form.propStr, this.form.touchStr)
          }
          this.count += 1
        })
      },
      // 条件参数
      condition(propStr, touchStr) {
        this.form.propStr = propStr
        this.form.touchStr = touchStr
        this.form.condition = propStr || touchStr ? '已选择' : '未选择'
        this.conditionShow = false
      },
      // 创建
      create() {
        this.taskShow = true
        this.pageType = 'create'
        for (const key in this.form) {
          this.form[key] = ''
        }
        this.form.condition = '未选择'
        this.form.sendType = 0
        this.form.time = 1
        this.count = 0
      },
      // 修改状态
      editState(item, index) {
        let data = {
          id: item.id,
          hotelId: this.hotelInfo.id,
          state: item.type ? this.customState[index] : this.systemState[index],
        }
        editShortMessage(data).then(({ success }) => {
          if (success) {
            this.$message({ showClose: true, message: '修改成功!', type: 'success' })
            this.getTaskList()
          }
        })
      },
      // 编辑
      edit(item) {
        this.taskShow = true
        this.pageType = 'edit'
        this.form.name = item.name
        this.form.sendType = item.sendType
        this.form.time = item.delayTime || 1
        this.form.propStr = item.propStr
        this.form.touchStr = item.touchStr
        this.form.content = item.messageText
        this.form.id = item.id
        this.form.condition = item.propStr || item.touchStr ? '已选择' : '未选择'
      },
      // 删除
      handleDel(){
         this.$confirm(this.confirm_remove, this.prompt, {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'error'
         }).then(() => {
            deleteTask(this.form.id).then(({ success })=>{
              if (success) {
                this.taskShow = false
                this.getTaskList()
                this.$message.success({showClose: true, message: this.remove_success, type: 'success' })
              }
            })
         })
      },
      // 确定
      onSubmit(form) {
        this.$refs[form].validate((valid) => {
          if (valid) {
            let data = {
              hotelId: this.hotelInfo.id,
              name: this.form.name,
              propStr: this.form.propStr || '',
              touchStr: this.form.touchStr || '',
              type: 1,
              sendType: this.form.sendType,
              messageText: this.form.content,
            }
            this.form.sendType && (data.delayTime = this.form.time)
            if (this.pageType === 'create') {
              addShortMessage(data).then(({ success }) => {
                if (success) {
                  this.taskShow = false
                  this.$message({ showClose: true, message: '创建成功!', type: 'success' })
                  this.getTaskList()
                }
              })
            } else {
              data.id = this.form.id
              editShortMessage(data).then(({ success }) => {
                if (success) {
                  this.taskShow = false
                  this.$message({ showClose: true, message: '编辑成功!', type: 'success' })
                  this.getTaskList()
                }
              })
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      // 改变每页数
      pageChange(num) {
        this.limit = num
        this.detail()
      },
      // 改变当前页
      handlePaging(num) {
        this.page = num
        this.detail()
      },
    },
    filters: {
      time(val) {
        let date = val.split('T')
        let arr = date[0].split('-')
        return `${arr[0]}年${arr[1]}月${arr[2]}日 ${date[1]}`
      },
    },
  }
</script>

<style lang="scss" scoped>
  .timingMessages {
    .text-color {
      color: #303133;
    }
    .content-box {
      .task {
        padding-left: 30px;
        display: flex;
        flex-wrap: wrap;
        .item {
          width: 31%;
          margin-top: 40px;
          margin-right: 48px;
          /deep/ .el-card__body {
            padding: 0;
            .text {
              margin: 20px;
              height: 63px;
              text-align: justify;
            }
            .footer {
              padding: 8px 20px;
              border-top: 1px solid #ebeef5;
            }
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
      .btn {
        position: absolute;
        top: 50px;
        right: 50px;
      }
    }
  }
</style>
