<template>
   <div class="condition-popup">
      <div class="m-bottom-20">
         <el-row class="title m-bottom-15">用户属性</el-row>
         <el-row class="item" v-for="(item, index) of propStr" :key="index">
            <el-select @change="item.value = ''" class="width-175" size="small" v-model="item.field" placeholder="请选择"
                       :disabled="action === 'look'">
               <el-option
                   v-for="item in fieldList"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
               </el-option>
            </el-select>
            <span class="select-box">等于</span>
            <span>
               <el-input class="width-175" v-if="item.field === 'age' || item.field === 'address' || item.field == 'balance'" type="text"
                         v-model="item.value" size="small" :disabled="action === 'look'" placeholder="请输入"></el-input>
               <el-radio-group v-model="item.value" v-else-if="item.field == 'company_name'">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
               </el-radio-group>
               <el-date-picker
                   v-else-if="item.field === 'create_time'"
                   :disabled="action === 'look'"
                   v-model="item.value"
                   type="daterange"
                   align="right"
                   size="small"
                   unlink-panels
                   range-separator="至"
                   start-placeholder="开始日期"
                   end-placeholder="结束日期"
                   value-format="yyyy-MM-dd"
                   :picker-options="pickerOptions">
               </el-date-picker>
               <el-select
                   v-else
                   size="small"
                   class="width-175"
                   v-model="item.value"
                   :disabled="action === 'look'"
                   placeholder="请选择"
                   >
                  <el-option v-for="item in computeList(item.field)" :key="item.value" :label="item.label" :value="item.value"></el-option>
               </el-select>
            </span>
            <i class="el-icon-delete" @click="removeData('prop', item.id)"></i>
         </el-row>
         <el-button size="mini" @click="insertData('prop')" :disabled="action === 'look'">添加</el-button>
      </div>
      <div class="m-bottom-20">
         <el-row class="title m-bottom-15">触发条件</el-row>
         <el-row class="item" v-for="(item, index) of touchStr" :key="index">
            <el-select @change="item.value = ''" class="width-175 m-right-5" size="small" v-model="item.field" placeholder="请选择"
                       :disabled="action === 'look'">
               <el-option
                   v-for="item in tagTypeList"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
               </el-option>
            </el-select>
            <el-select class="width-175 m-right-5" size="small" v-model="item.operator" placeholder="请选择"
                       :disabled="action === 'look' || item.field === 'create_time' || item.field === 'login_time'">
               <el-option
                   v-for="item in operatorList"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
               </el-option>
            </el-select>
            <span>
               <el-input class="width-175" v-if="item.field === 'money' || item.field === 'times'" type="text"
                         v-model="item.value" size="small" :disabled="action === 'look'" placeholder="请输入"></el-input>
               <el-date-picker
                   v-else
                   :disabled="action === 'look'"
                   v-model="item.value"
                   type="daterange"
                   align="right"
                   size="small"
                   unlink-panels
                   range-separator="至"
                   start-placeholder="开始日期"
                   end-placeholder="结束日期"
                   value-format="yyyy-MM-dd"
                   :picker-options="pickerOptions">
               </el-date-picker>
            </span>
            <i class="el-icon-delete" @click="removeData('touch', item.id)"></i>
         </el-row>
         <el-button size="mini" @click="insertData('touch')" :disabled="action === 'look'">添加</el-button>
      </div>
      <div slot="footer" class="dialog-footer">
         <el-button size="medium" @click="$emit('close')">取 消</el-button>
         <el-button size="medium" type="primary" @click="saveData">确 定</el-button>
      </div>
   </div>
</template>
<script>
import { mapState } from 'vuex'
import { getDict } from "@/common/js/common";
import {urlObj} from "@/api/interface";
import {label} from "@/api/interface/data";
export default {
   props: {
      all: {
         type:Boolean,
         value: false
      }
   },
   data() {
      return {
         fieldList: [
            { label: '性别', value: 'gender' },
            { label: '会员等级', value: 'level' },
            { label: '年龄', value: 'age' },
            { label: '地址', value: 'address' },
            { label: '用户来源', value: 'source' },
            { label: '个人标签', value: 'tag_id' },
            { label: '是否有公司', value: 'company_name' },
            { label: '储值卡余额', value: 'balance'},
            { label: '注册时间', value: 'create_time' }
         ],
         tagTypeList: [
            { label: '销售额', value: 'money' },
            { label: '订单数量', value: 'times' },
            { label: '下单时间', value: 'create_time' },
            { label: '近期退款用户', value: 'refund_time' },
            { label: '登录小程序未付款', value: 'login_nopay' },
            { label: '登录小程序且付款', value: 'login_pay' },
            { label: '最近登录时间', value: 'login_time' },
         ],
         operatorList: [
            { label: '大于', value: 'GT' },
            { label: '等于', value: 'EQ' },
            { label: '小于', value: 'NE' }
         ],
         pickerOptions: {
            shortcuts: [{
               text: '最近一周',
               onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                  picker.$emit('pick', [start, end]);
               }
            }, {
               text: '最近一个月',
               onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                  picker.$emit('pick', [start, end]);
               }
            }, {
               text: '最近三个月',
               onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                  picker.$emit('pick', [start, end]);
               }
            }]
         },
         propStr: [],
         touchStr: [],
         levelList: [],
         tagList: [],
         id: 0,
         sourceList: [
            { label: '微信公众号',value: 8 },
            { label: '微信小程序',value: 1 },
            { label: '抖音小程序',value: 9 },
            { label: '自助机',value: 2 },
            { label: 'APP',value: 3 },
            { label: 'PC端',value: 4 },
            { label: '平台',value: 5 },
            { label: '短信',value: 6 },
            { label: '邮件',value: 7 },
            { label: 'QQ',value: 10 },
            { label: '支付宝',value: 11 },
            { label: '钉钉',value: 12 },
            { label: '抖音',value: 13 },
            { label: '小红书',value: 14 },
            { label: '酒店PMS',value: 15 },
         ],
         action: ''
      }
   },
   computed: {
      ...mapState(['hotelInfo', 'dictData']),
      computeList() {
         return function (field) {
            if (field === 'level') return this.levelList
            if (field === 'tag_id') return this.tagList
            if (field === 'source') return this.sourceList
            if (field === 'gender') {
               const genderList = []
               for (const key in this.dictData['gender']) genderList.push({ label: this.dictData['gender'][key], value: key })
               return genderList
            }
         }
      }
   },
   mounted() {
      if (this.all) this.fieldList = this.fieldList.filter(item => item.value != 'level' && item.value != 'tag_id')
      getDict(['gender'])
      this.action = this.$route.query.action
      if (this.action === 'edit' || this.action === 'look') this.echoCondition()
      this.getLevels()
      this.getMembersTags()
   },
   methods: {
      // 回显查询条件
      echoCondition() {
         const data = JSON.parse(sessionStorage.getItem('groupInfo') || sessionStorage.getItem('tagInfo') || sessionStorage.getItem('tempInfo'))
         if (data.hasOwnProperty('propStr') && data.propStr) this.parse(data.propStr, 'propStr')
         if (data.hasOwnProperty('touchStr') && data.touchStr) this.parse(data.touchStr, 'touchStr')
      },
      // 数据格式转换
      parse(str, par) {
         JSON.parse(str).forEach(item => {
            item.id = this.id += 1
            if ('beginDate' in item) {
               item.value = []
               item.value.push(item.beginDate, item.endDate)
               delete item.beginDate
               delete item.endDate
            }
            this[par].push(item)
         })
      },
      // 插入数据
      insertData(type){
         type === 'prop' && this.propStr.push({ id: this.id += 1, field: 'gender', operator: 'EQ', value: ''})
         type === 'touch' && this.touchStr.push({ id: this.id += 1, field: 'money', operator: 'EQ', value: ''})
      },
      // 移除数据
      removeData(type, id){
         type === 'prop' && (this.propStr = this.propStr.filter(item => item.id !== id))
         type === 'touch' && (this.touchStr = this.touchStr.filter(item => item.id !== id))
      },
      // 数据格式转换
      stringify(str) {
         return JSON.stringify(str.map(item => {
            const obj = {}
            obj.field = item.field
            obj.operator = item.operator
            if (item.value.constructor === Array) {
               obj.beginDate = item.value[0]
               obj.endDate = item.value[1]
            }else obj.value = item.value
            return obj
         }))
      },
      // 保存数据
      saveData() {
         let propStr, touchStr
         this.propStr.length > 0 && (propStr = this.stringify(this.propStr))
         this.touchStr.length > 0 && (touchStr = this.stringify(this.touchStr))
         this.$emit('condition', propStr, touchStr)
      },
      // 获取会员等级下拉列表
      getLevels(){
         const url = urlObj.memberLevels
         const param = { companyId: this.hotelInfo.storeId }
         this.$axios.post(url, param).then(res => {
            if (!res.success) return
            this.levelList = res.records.map(item => ({ label: item.medalName, value: item.medalCode }))
         })
      },
      // 获取个人标签下拉列表
      getMembersTags(){
         const url = label.tagLibrary
         const param = { companyId: this.hotelInfo.storeId }
         this.$axios.post(url, param).then(res => {
            if (res.success){
               let tagList = res.records
               this.tagList = tagList.map(item => ({ label: item.tagName, value: item.id }))
            }
         })
      },
      // 父组件传递事件 用于数据回显
      transmit(propStr,touchStr){
         this.propStr = []
         this.touchStr = []
         propStr  && this.parse(propStr, 'propStr')
         touchStr  && this.parse(touchStr, 'touchStr')
      }
   },
}
</script>
<style lang="scss" scoped>
.condition-popup{
   overflow: hidden;
   .title{ font-size: 16px; font-weight: bold }
   .item{
      font-size: 12px;
      margin-bottom: 5px;
      ::v-deep .select-box{
         padding: 5px;
         margin: 0 5px 0 0;
         color: #222222;
         input{
            font-size: 12px;
            border: none;
            background-color: #deeffa;
            outline: none;
         }
         .input-num{
            width: 26px;
            text-align: center;
            color: #1489e2;
         }
         .el-range-editor--mini.el-input__inner{
            width: 220px;
            margin-right: 5px;
         }
         .el-input__inner{
            border: none; background-color: transparent;
            color: #1489e2; height: 24px; font-size: 12px;
         }
         .el-input__suffix .el-input__icon{ line-height: 32px }
      }
      .el-icon-delete{ line-height: 30px; margin-left: 5px; font-size: 16px }
      &:hover { background-color: #ecf3f8; }
   }
   .dialog-footer{ float: right }
}
</style>
